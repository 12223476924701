// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import StimulusControllerResolver from 'stimulus-controller-resolver';
import { Tabs } from 'tailwindcss-stimulus-components';

const application = Application.start();

// Register used components from TailwindCSS Stimulus Components.
application.register('tabs', Tabs);

// Load controllers lazily.
StimulusControllerResolver.install(application, async (controllerName) => (
  (await import(`./${controllerName.replace(/--/g, '/').replace(/-/g, '_')}_controller`)).default
));
