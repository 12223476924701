// CustomEvent()
// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
if (typeof window.CustomEvent !== 'function') {
  window.CustomEvent = function customEvent(event, params) {
    const evtParams = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, evtParams.bubbles, evtParams.cancelable, evtParams.detail);

    return evt;
  };
}

// Element.closest()
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
                              || Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function elementClosest(s) {
    let el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

// ChildNode.remove()
// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
(function childNodeRemove(arr) {
  arr.forEach((item) => {
    if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        this.parentNode.removeChild(this);
      },
    });
  });
}([Element.prototype, CharacterData.prototype, DocumentType.prototype]));

// [CSS] object-fit
// https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit
// https://stackoverflow.com/a/37792830
if ('objectFit' in document.documentElement.style === false) {
  document.addEventListener('DOMContentLoaded', () => {
    Array.prototype.forEach.call(document.querySelectorAll('img[data-object-fit]'), (image) => {
      const objectFitValue = image.currentStyle
        ? image.currentStyle['object-fit']
        : image.getAttribute('data-object-fit');
      (image.runtimeStyle || image.style).background = `url("${image.src}") no-repeat 50%/${objectFitValue}`;
      image.src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${image.width}' height='${image.height}'%3E%3C/svg%3E`;
    });
  });
}

// Splide-Stimulus
if (!window.MutationObserver) {
  document.addEventListener('DOMContentLoaded', () => {
    Array.prototype.forEach.call(document.querySelectorAll('.splide'), (splideElement) => {
      splideElement.classList.add('twp-visible');
    });
  }, false);
}
